<template>
  <rs-row>
    <rs-col :lg="12" :md="12" :sm="12">
      <rs-card :title="'2023年回款情况:(合计:' + yeahMoney + ')'">
        <ve-histogram :data="MonthMoney"></ve-histogram>
      </rs-card>
    </rs-col>
    <rs-col :lg="12" :md="12" :sm="12">
      <rs-card :title="'2023年月销售情况:(合计:' + yeahSum + ')'">
        <ve-histogram :data="MonthSum"></ve-histogram>
      </rs-card>
    </rs-col>
  </rs-row>
</template>
<script>
import request from '@/utility/request'
export default {
  name: 'chart-card',
  data() {
    return {
      custom: [],
      yeahSum: 0,
      yeahMoney: 0,
      yeahPay: 0
    }
  },
  mounted() {
    request.get('/custom/getCustomSumMontyPay?yeah=2023').then(data => {
      this.custom = data.data
    })
  },
  computed: {
    MonthSum() {
      var MonthSum = {
        columns: ['日期', '销售额'],
        rows: this.custom[0]
      }
      if (!this.custom[0]) return MonthSum
      this.custom[0].forEach(e => {
        this.yeahSum += parseInt(e['销售额'])
      })
      return MonthSum
    },
    MonthMoney() {
      var MonthMoney = {
        columns: ['日期', '回款额'],
        rows: this.custom[1]
      }
      if (!this.custom[1]) return MonthMoney
      this.custom[1].forEach(e => {
        this.yeahMoney += parseInt(e['回款额'])
      })
      return MonthMoney
    },
    monthPay() {
      var MonthPay = {
        columns: ['日期', '成本额'],
        rows: this.custom[2]
      }
      return MonthPay
    }
  }
}
</script>
